//
// aos.js
// Theme module
//

import AOS from 'aos';

const options = {
    duration: 700,
    easing: 'ease-out-quad',
    once: true,
    startEvent: 'load',
    disable: '.no-aos', // Exclude elements with the 'no-aos' class from AOS animations
};

AOS.init(options);
window.AOS = AOS;